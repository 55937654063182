<template>
  <div>
    <Breadcrumbs main="" title="Status Page" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div slot="with-padding">
            <b-row class="mt-3">
                <b-col md="3" class="input-group">
                  <div class="form-group input-group-square">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class=""
                        v-model="filter"
                        placeholder="Type to Search"
                      ></b-form-input>
                    </b-input-group>
									</div>
                </b-col>
                <b-col class="mt-2 mt-md-0 text-md-left text-center">
                  <b-button size="lg" variant="primary" class="btn" @click="add_status_modal = true" >Add Status Page +</b-button>
                </b-col>

                <b-col class="col-md-6 col-12 mt-2 mt-md-0">
                  <b-row>
                    <b-col class="col-md-12 text-right">
                      Per Page
                      <b-form-select
                      class="col-md-2 col-6"
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
     
                <div class="table-responsive datatable-vue text-left">
                <b-table
                  show-empty
                  stacked="md"
                  :items="items"
                  :filter="filter"
                  :current-page="currentPage"
                  :fields="tablefields"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                <template #cell(name)="data">
                  <b class="text-primary"><a :href="statusPageurl+data.item._id" target="_blank" title="View Status Page" rel="noopener noreferrer">{{ data.item.name }}</a></b>
                </template>
                <template #cell(pagePassword)="data">
                   ************
                </template>
                <template #cell(homepageurl)="data">
                  <span class="text-primary">{{ data.item.homepageurl }}</span>
                </template>
                <template #cell(customDomain)="data">
                  <span class="text-primary">{{ data.item.customDomain }}</span>
                </template>
                <template #cell(createdAt)="data">
                  <span>{{ changeDateFormate(data.item.createdAt) }}</span>
                </template>
                
                <template #cell(status)="data">
                    <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=data.item.status @change="statusUpdate(data.item)"><span class="switch-state" :class="data.item.status?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>                 
                </template>
                <template #cell(action)="data">
                  <feather class="text-danger" type="trash-2" title="Delete" @click="deleteStatus(data.item._id)"></feather>
                  <feather class="text-info ml-2" type="edit" title="Edit" @click="editStatus(data.item)"></feather>         
                  <a :href="statusPageurl+data.item._id" target="_blank" title="View Status Page" rel="noopener noreferrer"><feather class="text-info ml-2" type="eye"></feather>  </a>       
                </template> 
                </b-table>
              </div>
             
          </div>
        </div>
      </div>
    </div>
    <b-modal id="status-monitor" size="lg" title="Public Status Page Information" class="theme-modal" @hide="closeModal" v-model="add_status_modal" no-close-on-backdrop hide-footer>
        <add-status-page @statusmodal="closeModal" :add_status_modal="add_status_modal" :edit_status="edit_status" :status_data="status_data"></add-status-page>
    </b-modal>
  </div>
</template>



<script>
import moment from 'moment'
import AddStatusPage from './AddStatusPage.vue';
import StatusPageController from "../../services/statuspageController";
import Config from "../../services/config"
export default{
  components: { AddStatusPage },
    name:'StatusPage',
    data(){
        return{
            add_status_modal:false,
            items:[],
            totalRows:0,
            perPage: 10,
            pageOptions: [5, 10, 50],
            currentPage: 1,
            filter:null,
            edit_status:false,
            tablefields:[
              { key: "name", label: "Name", sortable: true },
              // { key: "homepageurl", label: "Home Page Url", sortable: true },
              // { key: "customDomain", label: "Custom Domain", sortable: true },
              { key: "status", label: "Status", sortable: false },
              { key: "pagePassword", label: "Page password", sortable: false },
              { key: "createdAt", label: "Created At", sortable: true },
              { key: "action", label: "Action", sortable: false },
            ],
            status_data:{},
            statusPageurl:''
        }
    },
    mounted(){
      this.statusPageurl = Config.statusPageUrl
      this.getAllStatus()
    },
    methods:{
      closeModal(){
          this.add_status_modal = false
          this.edit_status = false;
          this.getAllStatus();
        },
      async getAllStatus(){
        this.items = []
            let response = await StatusPageController.getAll()
            if (response.result) {
                this.items = response.data;
                this.totalRows = this.items.length;
            }
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async deleteStatus(id){
        if(confirm('Are you sure to delete this status?')){
        let response = await StatusPageController.deleteStatus({"spid": id});
          if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            this.getAllStatus()
          }
          else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
          }
      }
      },
      changeDateFormate(d){
          return moment(d).format('D/M/YYYY, HH:mm A')
      },
      editStatus(data){
          this.add_status_modal = false
          this.add_status_modal = true
          this.status_data = data;
          this.edit_status = true;
      },
      async statusUpdate(data){
        // this.loading = true;
        var s = true
        if(data.status == true){
            s = false
        }
        if (data.status == false) {
          data.status = true;
        } else {
          data.status = false;
        }
        var payload = {"spid": data._id, "changePayload": {"status":s}}
        let response = await StatusPageController.updateStatusPage(payload)
        if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        }
        else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
            this.getAllStatus()
        }
      },
  }
}
</script>