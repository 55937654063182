import api from './api'
export default {
    getMonitors(data){
        var payload = '';
        if(data){ payload = data}
        return api.execute(`GET`,`/monitor`+payload)
    },
    createMonitor(data){
        return api.execute(`POST`,`/monitor`,data)
    },
    deleteMonitor(data){
        return api.execute(`DELETE`,`/monitor`,data)
    },
    editMonitor(data){
        return api.execute(`PUT`,`/monitor`,data)
    },
    statusChange(data){
        return api.execute(`POST`,`/monitor/chgstatus`,data)
    },
    csvUpload(data){
        return api.execute(`POST`,`/monitor/import`,data)
    },
    getAlerts(){
        return api.execute(`GET`,`/acontact`)
    },
    bulkAction(data){
        return api.execute(`POST`,`/monitor/bulk`,data)
    }
}